import type { LearningUnitPathData } from "@generated/graphql";
import type { UrlObject } from "node:url";
import { slugify } from "@utils/slugify";

export const isPlatformBrowser = () => {
  return typeof window !== "undefined";
};

export type Env = "local" | "test" | "staging" | "production";
export const getEnvironment = (): Env => {
  const env = process.env.NEXT_PUBLIC_IL_ENV;
  if (env === "local" || env === "test" || env === "staging" || env === "production") {
    return env;
  }

  throw new Error(`Invalid environment. Found (${env}) but expected local, staging or production.`);
};

export const noop = () => {};

export const assertNever = (value: never): never => {
  throw new Error(`Unexpected value in assertNever: ${value}`);
};

/**
 * Extracts base domain from a URI, where the domain consists of:
 *
 * `<protocol>://<hostname>(:<port>)`
 */
export function domain(src: string): string {
  const url = new URL(src);
  const base = `${url.protocol}//${url.hostname}`;

  return url.port ? `${base}:${url.port}` : base;
}

export function getHomePageBackRoute(showOverlay = true): UrlObject {
  const route: UrlObject = {
    pathname: "/",
  };

  if (showOverlay) {
    route.query = { firstVisit: "true" };
  }

  return route;
}

export const getContentPath = (pathData: LearningUnitPathData, learningUnitId: string): string => {
  const isLuMissing = learningUnitId === "";
  const isParagraphMissing = pathData.paragraphId === "";
  const isChapterMissing = pathData.chapterId === "";
  const isMethodGroupMissing = pathData.methodGroupId === "";
  const isMethodMissing = pathData.methodId === "";
  const isSubjectMissing = pathData.subject === "";
  const pathStart = "/content/";

  if (isSubjectMissing) {
    return `${pathStart}`;
  }

  if (isMethodMissing) {
    return `${pathStart}${slugify(pathData.subject)}`;
  }

  if (isChapterMissing || isMethodGroupMissing) {
    return `${pathStart}${slugify(pathData.subject)}/${pathData.methodId}`;
  }

  if (isLuMissing || isParagraphMissing) {
    return `${pathStart}${slugify(pathData.subject)}/${pathData.methodId}/${
      pathData.methodGroupId
    }/${pathData.chapterId}`;
  }

  return `${pathStart}${slugify(pathData.subject)}/${pathData.methodId}/${pathData.methodGroupId}/${
    pathData.chapterId
  }/${pathData.paragraphId}/${learningUnitId}`;
};

export const shouldDisplayDiffSelectionModal = ({
  usedDifferentiationRoutes,
  isCompleted,
  isExtraPracticing,
  usedDiffRoutesLoading,
  usedDiffRoutesError,
  hasActiveSchedule,
  hasOngoingAttempt,
}: {
  usedDifferentiationRoutes: number | undefined;
  isCompleted: boolean;
  isExtraPracticing: boolean;
  usedDiffRoutesLoading: boolean;
  usedDiffRoutesError: unknown;
  hasActiveSchedule: boolean;
  hasOngoingAttempt: boolean;
}): boolean => {
  // if the current attempt is completed or there is no attempt yet and the extra practicing has differentiation routes,
  // we show the modal to let the user select the diff route on which they would like to practice on
  // BUT if there's an active schedule, we should not show the modal because the pupil should use the differentiation route assigned to them
  return (
    !usedDiffRoutesLoading &&
    !usedDiffRoutesError &&
    isExtraPracticing &&
    !!usedDifferentiationRoutes &&
    usedDifferentiationRoutes > 0 &&
    (isCompleted || !hasOngoingAttempt) &&
    !hasActiveSchedule
  );
};

export const unknownToError = (unknown: unknown): Error => {
  if (unknown instanceof Error) {
    return unknown;
  }

  return new Error(String(unknown));
};

// Convert a date to UTC while subtracting the timezone
// Temporary workaround for: https://github.com/orgs/infinitaslearning/projects/111/views/1?pane=issue&itemId=81346294
export const convertToUTC = (date: Date) => {
  // Get the local timezone offset in milliseconds
  const localTimezoneOffset = date.getTimezoneOffset() * 60000; // Offset in minutes to milliseconds

  // Create a new date in UTC by subtracting the local timezone offset
  const utcDate = new Date(date.getTime() + localTimezoneOffset);

  return utcDate;
};
