import { useContext, useMemo } from "react";

import { UserContext } from "@components/user-context/user-context";

const FEATURE_FLAGS = [
  "showPracticingPupilFrameOption",
  "useNewCustomFonts",
  "hidePlayerAudioBar",
  "tallyConfigCsat",
  "useDrillPlayer",
  "useSlimStampenDrillingPlayer",
  "umcAnnualPreparation",
  "useAzureTTS",
  "useNewPlayerDesign",
  "newLessonCards",
  "usemydaynotificationbadge",
] as const;

export type FeatureFlag = (typeof FEATURE_FLAGS)[number];

export const useFeatureFlag = <T>(featureName: FeatureFlag, defaultValue: T): T => {
  const userContext = useContext(UserContext);
  const value = useMemo(() => {
    const featureFlagValue = userContext?.features?.find((x) => x.key === featureName)?.value;
    return featureFlagValue ?? defaultValue;
  }, [userContext, featureName, defaultValue]);

  return value;
};

export const useFeatureFlags = <T extends FeatureFlag>(featureNames: readonly T[]) => {
  const userContext = useContext(UserContext);

  const value = useMemo(() => {
    const featureFlagValues = featureNames.map((featureName) => {
      const value = userContext?.features?.find((x) => x.key === featureName)?.value;
      const parsedValue = value === undefined || value === null ? undefined : Boolean(value);
      return [featureName, parsedValue];
    });
    return Object.fromEntries(featureFlagValues) as Record<T, boolean | undefined>;
  }, [userContext, featureNames]);

  return value;
};
